// ================   LOCAL    ================== //
export const API_NAPNEGOCIOS = process.env.REACT_APP_API_NAPNEGOCIOS || 'http://192.168.1.30:3060/api';
// export const API_COBRANZAS = process.env.REACT_APP_API_COBRANZAS || 'http://192.168.1.30:7000/api';
export const API_REPORTES = process.env.REACT_APP_API_REPORTES || 'http://192.168.1.30:3060/api';
// export const API_SUNAT = process.env.REACT_APP_API_SUNAT || 'http://192.168.1.30:3060/api';

// ================ PRODUCCIÓN ================== //
// export const API_NAPNEGOCIOS = process.env.REACT_APP_API_NAPNEGOCIOS || 'https://back-general.napnegocios.com/api';
export const API_COBRANZAS = process.env.REACT_APP_API_COBRANZAS || 'https://back.pagos.napnegocios.com/api';
// export const API_REPORTES = process.env.REACT_APP_API_REPORTES || 'https://reportes-general.napnegocios.com/api';
export const API_SUNAT = process.env.REACT_APP_API_SUNAT || 'https://sunat-general.napnegocios.com/api';
